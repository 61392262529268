import React, { useEffect } from "react";
import { Head, Spinner } from "component-library";
import { CheckoutAccessTypes } from "component-library/src/components/Organisms/Billing/types";
import { useTranslation } from "i18n";
import CheckoutPageWrapper from "../CheckoutPageWrapper";
import { useCanAccessCheckout } from "component-library/src/components/Organisms/Billing/hooks/useCanAccessCheckout";
import { USER_ACCOUNT_APP_ROOT } from "component-library/src/components/Organisms/Billing/constants";
import { useHistory, useParams } from "react-router-dom";
import applicationMap, {
  isAcceptedCheckoutType,
} from "services/applicationMap";

const Checkout = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!isAcceptedCheckoutType(type)) {
      return history.replace(applicationMap.routes.index());
    }
  }, [type]);

  const { t } = useTranslation();

  const { data: accessData, isLoading: isCanAccessCheckoutLoading } =
    useCanAccessCheckout(true, USER_ACCOUNT_APP_ROOT);

  const goBack = () => {
    if (accessData?.data.checkoutType === CheckoutAccessTypes.GradsTransfer)
      return history.push(applicationMap.routes.graduatesTransferInformation());
    return history.goBack();
  };

  if (isCanAccessCheckoutLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Head>
        <title>{t("common:billing.checkout.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper
        checkoutFlow={accessData?.data?.checkoutType}
        goBackOverride={goBack}
      />
    </>
  );
};

export default Checkout;
