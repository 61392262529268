export enum ActionType {
  GetNavigationSuccess = "GetNavigationSuccess",
  GetPageTranslationSuccess = "GetPageTranslationSuccess",
  GetCommonTranslationSuccess = "GetCommonTranslationSuccess",
  SetPageData = "SetPageData",
  ShowPopup = "ShowPopup",
  HidePopup = "HidePopup",
  SetLocale = "SetLocale",
  SetLoading = "SetLoading",
  SetPageLoading = "SetPageLoading",
  SetUserData = "SetUserData",
  SetUserType = "SetUserType",
  SetPage404 = "SetPage404",
}

export interface IAction<TType, TPayload> {
  type: TType;
  payload: TPayload;
}
