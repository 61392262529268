import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Head, Spinner, generateBreadcrumbsData } from "component-library";
import { useTranslation } from "i18n";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout/PageWrapperWithinLayout";
import { authorizationService } from "shared-auth";
import { MemberGroups } from "shared-types";
import UserAccountService from "services/api/UserAccount/UserAccountService";
import {
  IAddress,
  getRecurlyDetails,
  IBillingApiResponse,
} from "services/api/BillingApi/BillingApi";
import applicationMap, { CheckoutType } from "services/applicationMap";
import {
  IAccountSettingsLoginDetailsProps,
  IAccountSettingsLoginDetailsSecurityQ,
} from "models/page";
import { useNewAccSettingsPageAccessCheck } from "./parts/hooks/useNewAccSettingsPageAccessCheck";
import checkIsNoActiveSubscription from "helpers/checkIsNoActiveSubscription";
import checkIsValidAddress from "helpers/checkIsValidAddress";
import { useUpdateDetailsPopUp } from "app/hooks/useUpdateDetailsPopUp";
import { useReturnUrl } from "shared-hooks";
import { BreadcrumbSection, breadcrumbs } from "app/constants";
import { FieldNames } from "./MyDetails/constants";
import SettingSection from "components/SettingSection/SettingSection";

const AccountSettings: FC = () => {
  useNewAccSettingsPageAccessCheck();
  const { redirectWithReturnUrl } = useReturnUrl();

  const { t } = useTranslation();
  const history = useHistory();
  const { data: fetchedSettingsData, isLoading } = useQuery<
    [
      IAccountSettingsLoginDetailsProps | undefined,
      IAccountSettingsLoginDetailsSecurityQ | undefined,
      IBillingApiResponse | undefined
    ]
  >(
    "account-settings",
    async () =>
      await Promise.all([
        UserAccountService.getUserDetailsForSettingsPage(),
        UserAccountService.getUserSecurityQForSettingsPage(),
        getRecurlyDetails(),
      ])
  );
  const allowedMemberGroup =
    authorizationService.getUserGroup() === MemberGroups.performer;

  const [userDetails, userSecurityQ, recurlyDetails] =
    fetchedSettingsData ?? [];

  const isValidAddress = checkIsValidAddress(recurlyDetails?.address);
  const reactivationLink = applicationMap.routes.checkout({
    type: CheckoutType.reactivation,
  });

  const { showUpdateDetailsPopUp } = useUpdateDetailsPopUp(() =>
    redirectWithReturnUrl(
      applicationMap.routes.settingsMyDetails(),
      reactivationLink
    )
  );

  if (isLoading) return <Spinner />;

  const getAddressValue = () => {
    const address: IAddress | undefined = recurlyDetails?.address;

    if (!address) return "-";

    const addressLine1 = address[FieldNames.street1] ?? "";
    const addressLine2 = address[FieldNames.street2]
      ? `, ${address[FieldNames.street2]}`
      : "";
    const city = address[FieldNames.city]
      ? `, ${address[FieldNames.city]}`
      : "";
    const postalCode = address[FieldNames.postalCode]
      ? `, ${address[FieldNames.postalCode]}`
      : "";
    const region = address[FieldNames.region]
      ? `, ${address[FieldNames.region]}`
      : "";
    const country = address[FieldNames.country]
      ? `, ${address[FieldNames.country]}`
      : "";

    const fullAddress = `${addressLine1}${addressLine2}${city}${postalCode}${region}${country}`;

    return fullAddress || "-";
  };

  const noActiveSubscription = checkIsNoActiveSubscription({
    id: recurlyDetails?.account?.id,
    subscription: recurlyDetails?.subscription,
  });
  const showSubscriptionBlock =
    allowedMemberGroup &&
    (noActiveSubscription || recurlyDetails?.subscription);

  return (
    <>
      <Head>
        <title>{t("performer:accountSettings.pageTitle")}</title>
      </Head>
      <PageWrapperWithinLayout
        pageHeader={t("performer:accountSettings.pageTitle")}
        breadcrumbs={generateBreadcrumbsData(breadcrumbs, [
          BreadcrumbSection.BackToAccount,
        ])}
      >
        <SettingSection
          sectionHeader={t("performer:accountSettings.myDetails.header")}
          sectionDescription={t(
            "performer:accountSettings.myDetails.description"
          )}
          sectionData={[
            {
              label: t("common:label.name"),
              value: userDetails?.user.fullName ?? "-",
            },
            {
              label: t("common:label.phoneNumber"),
              value: recurlyDetails?.address?.phone ?? "-",
            },
            {
              label: t("common:label.address"),
              value: getAddressValue(),
            },
          ]}
          actionButtonLabel={t("common:button.label.updateMyDetails")}
          actionButtonOnClick={() =>
            history.push(applicationMap.routes.settingsMyDetails())
          }
        />
        <hr />
        <SettingSection
          sectionHeader={t("performer:accountSettings.myLoginDetails.header")}
          sectionDescription={t(
            "performer:accountSettings.myLoginDetails.description"
          )}
          sectionData={[
            {
              label: t("common:label.username"),
              value: userDetails?.user?.username ?? "-",
            },
            {
              label: t("common:label.emailId"),
              value: userDetails?.user?.email ?? "-",
            },
            {
              label: t("common:label.password"),
              value: "********",
            },
            {
              label: t("common:label.securityQuestion"),
              value: userSecurityQ?.question ?? "-",
            },
          ]}
          actionButtonLabel={t("common:button.label.updateMyLogInDetails")}
          actionButtonOnClick={() =>
            history.push(applicationMap.routes.settingsMyLoginDetails())
          }
        />
        <hr />

        {showSubscriptionBlock && (
          <SettingSection
            sectionHeader={t("performer:accountSettings.mySubscription.header")}
            sectionDescription={t(
              "performer:accountSettings.mySubscription.description"
            )}
            sectionData={[
              {
                label: t("common:label.subscriptionType"),
                value: noActiveSubscription
                  ? t("performer:accountSettings.noActiveSubscription")
                  : recurlyDetails?.subscription?.plan,
              },
            ]}
            actionButtonLabel={
              noActiveSubscription
                ? t("common:button.label.reactivateMembership")
                : t("common:button.label.manageSubscription")
            }
            actionButtonOnClick={() => {
              if (noActiveSubscription && !isValidAddress)
                return showUpdateDetailsPopUp();

              if (noActiveSubscription) return history.push(reactivationLink);

              return history.push(
                applicationMap.routes.settingsMySubscription()
              );
            }}
            actionButtonHidden={noActiveSubscription}
          />
        )}
      </PageWrapperWithinLayout>
    </>
  );
};

export default AccountSettings;
