// Currently not in use; will be used in future when we allow add on upgrades.

import React, { FC } from "react";
import {
  BillingContextProvider,
  BreadcrumbBar,
  CheckoutFlow,
  Head,
  PlanFilter,
  Spinner,
  generateBreadcrumbsData,
} from "component-library";
import { SinglePageCheckoutNew } from "component-library/src/components/Organisms/Billing";
import { useTranslation } from "i18n";
import { authorizationService } from "shared-auth";
import { BillingUser } from "translations/src/models/billing";
import Layout from "app-shell/Layout/Layout";
import {
  changeSubscription,
  ChangeSubscriptionType,
  getRecurlyDetails,
  IBillingApiResponse,
  MY_SUBSCRIPTION_QK,
} from "services/api/BillingApi/BillingApi";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";
import { useNewAccSettingsPageAccessCheck } from "pages/AccountSettings/parts/hooks/useNewAccSettingsPageAccessCheck";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import { useQuery } from "react-query";
import { PlanCodePeriod } from "component-library/src/components/Organisms/Billing/types";
import { BreadcrumbSection, breadcrumbs } from "app/constants";

const ChangeSubscription: FC = () => {
  useNewAccSettingsPageAccessCheck();
  const { t } = useTranslation();
  const history = useHistory();

  const { data: mySubscriptionData, isLoading } = useQuery<
    IBillingApiResponse | undefined
  >({
    queryKey: MY_SUBSCRIPTION_QK,
    queryFn: async () => await getRecurlyDetails(),
  });

  const postCheckoutCallback = async (
    data: any,
    trackingData: Record<string, any>
  ) => {
    const type =
      data?.planTypeFilter === PlanFilter.Premium
        ? ChangeSubscriptionType.Upgrade
        : ChangeSubscriptionType.Downgrade;

    await changeSubscription(type, {
      planId: data?.planId,
      couponCodes: data?.couponCodes,
    });

    await authorizationService.signInSilent();

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.ChangeSubscription,
      memberTier: data?.planTypeFilter,
      ...trackingData,
    });

    history.push(applicationMap.routes.settingsMySubscription());
  };

  const { subscription } = mySubscriptionData || {};

  const isAnnual = subscription?.planCode.includes(PlanCodePeriod.Annual);
  const isPremium = subscription?.planCode.includes(PlanFilter.Premium);
  const isHiddenPeriodToggle = isAnnual && !isPremium;

  const preselectedPlan = isPremium ? PlanFilter.Premium : PlanFilter.Classic;
  const preselectedPeriod = isAnnual
    ? PlanCodePeriod.Annual
    : PlanCodePeriod.Monthly;

  if (isLoading) return <Spinner />;

  return (
    <>
      <Head>
        <title>
          {t("performer:accountSettings.mySubscriptionPage.header")}
        </title>
      </Head>
      <Layout contentContainerCustomClassName="bg-color-neutral-three">
        <BreadcrumbBar
          items={generateBreadcrumbsData(breadcrumbs, [
            BreadcrumbSection.MyAccount,
            BreadcrumbSection.AccountSettings,
            BreadcrumbSection.MySubscriptions,
          ])}
        />
        <h1 className="p-[1rem] text-[1.875rem] leading-[2.25rem]">
          {t("performer:accountSettings.mySubscriptionPage.header")}
        </h1>
        <div className="px-4 py-5 h-[calc(100%-80px)]">
          <div className="p-[1rem] h-full shadow-[0_0_15px_0_rgba(0,0,0,0.2)] bg-white rounded">
            <BillingContextProvider
              billingUserType={BillingUser.Performer}
              checkoutFlow={CheckoutFlow.AccountSettings}
              goBackText={t("common:button.label.cancel")}
              checkoutText={t("common:button.label.continue")}
              postCheckoutCallback={postCheckoutCallback}
              goBack={() =>
                history.push(applicationMap.routes.settingsMySubscription())
              }
              planTypeFilterOverride={PlanFilter.All}
              isHiddenPeriodToggle={isHiddenPeriodToggle}
              preselectedPlanOverride={preselectedPlan}
              planPeriodOverride={preselectedPeriod}
            >
              <SinglePageCheckoutNew />
            </BillingContextProvider>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ChangeSubscription;
