import { useQuery } from "react-query";
import { getRecurlyDetails } from "services/api/BillingApi/BillingApi";

const GET_ADDRESS_DETAIL_QK = "GET_ADDRESS_DETAIL_QK";

export default function useGetAddressDetail() {
  return useQuery(
    GET_ADDRESS_DETAIL_QK,
    () => {
      return getRecurlyDetails();
    },
    {
      cacheTime: 0,
    }
  );
}
