import { combineReducers } from "redux";

import pageReducer from "./page/page.reducer";
import { IPageState } from "./page/page.types";
import translationReducer from "./translation/translation.reducer";
import { ITranslationState } from "./translation/translation.types";
import UIReducer from "./ui/ui.reducer";
import { IUIState } from "./ui/ui.types";
import userReducer from "./user/user.reducer";
import { IUserState } from "./user/user.types";

export interface IApplicationState {
  ui: IUIState;
  user: IUserState;
  translation: ITranslationState;
  page: IPageState;
}

export const reducers = combineReducers({
  ui: UIReducer,
  user: userReducer,
  translation: translationReducer,
  page: pageReducer,
});
