import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";
import { MemberGroups } from "shared-types";
import { authorizationService } from "shared-auth";

const allowedMemberGroups = [
  MemberGroups.performer,
  MemberGroups.yPerformer,
  MemberGroups.graduatePerformer,
];

export function useNewAccSettingsPageAccessCheck() {
  const isAllowedMember = allowedMemberGroups.includes(
    authorizationService.getUserGroup() as MemberGroups
  );
  const history = useHistory();

  useEffect(() => {
    if (!isAllowedMember) {
      history.replace(applicationMap.routes.index());
    }
  }, [isAllowedMember]);
}
